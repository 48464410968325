<template>
    <div class="main">

        <agent-info-comp></agent-info-comp>

        <agent-sub-title>통계표</agent-sub-title>

        <div class="search">
            <partner-sub-selector-comp @setPartnerId="setPartnerId"></partner-sub-selector-comp>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색</el-button>
        </div>
        <div class="data">
            <table class="agent_table">
                <tr>
                    <th style="width: 3%">일짜</th>
                    <th style="width: 3%">신규회원</th>
                    <th style="width: 3%">블럭회원</th>
                    <th style="width: 3%">실베터</th>
                    <th style="width: 5%">첫충P</th>
                    <th style="width: 5%">매충P</th>
                    <th style="width: 5%">입금</th>
                    <th style="width: 5%">출금</th>
                    <th style="width: 3%">파트너출금</th>
                    <th style="width: 6%">손이익</th>
                    <th style="width: 5%">정산금액</th>
                    <th style="width: 6%">스포츠</th>
                    <th style="width: 6%">인플레이</th>
                    <th style="width: 6%">미니게임</th>
                    <th style="width: 7%">카지노</th>
                    <th style="width: 7%">슬롯</th>
                </tr>
                <tr v-for="item in sdata">
                    <td>
                        <span>{{item.date|datef('MM-DD')}}</span>

                    </td>
                    <td>
                        <span>{{item.siteMemberCount|comma}} 명</span>
                    </td>
                    <td>
                        <span>{{item.siteBlockMemberCount|comma}} 명</span>
                    </td>
                    <td>
                        <span> {{item.realBeter|comma}} 명</span>
                    </td>
                    <td>
                        <span>{{item.accumulateFirstRechargePoint|comma}}</span>
                    </td>
                    <td>
                        <span>{{item.accumulateDayRechargePoint|comma}}</span>
                    </td>
                    <td>
                        <span> {{item.rechargeTotal|comma}}</span>
                    </td>
                    <td>
                        <span>{{item.exchangeTotal|comma}}</span>
                    </td>
                    <td>
                        <span>{{item.exchangePartnerTotal|comma}}</span>
                    </td>
                    <td>
                        <span :class="{'text-red':(item.rechargeTotal - item.exchangeTotal) < 0}">
                            {{(item.rechargeTotal - item.exchangeTotal)|comma}}
                        </span>
                    </td>
                    <td>
                         <span :class="{'text-red':(item.partnerStatisticAmount) < 0}">
                            {{(item.partnerStatisticAmount)|comma}}
                        </span>
                    </td>
                    <td>
                        {{(item.sportsBetCashTotal - (item.sportsBetWinCashTotal+item.sportsBetCancelCashTotal+item.sportsBetSpecialCashTotal))|comma}}
                    </td>
                    <td>
                        {{(item.inplayBetCashTotal - (item.inplayBetWinCashTotal+item.inplayBetCancelCashTotal+item.inplayBetSpecialCashTotal))|comma}}
                    </td>
                    <td>
                        {{(item.leisureBetCashTotal - (item.leisureBetWinCashTotal+item.leisureBetCancelCashTotal+item.leisureBetSpecialCashTotal))|comma}}
                    </td>
                    <td>
                        {{(item.honorCasinoBetCashTotal - item.honorCasinoWinCashTotal)|comma}}
                    </td>
                    <td>
                        {{(item.honorSlotBetCashTotal - item.honorSlotWinCashTotal)|comma}}
                    </td>
                </tr>
                <tr>
                    <th>총합계</th>
                    <th>
                        <span>{{siteMemberCount|comma}} 명</span>
                    </th>
                    <th>
                        <span>{{siteBlockMemberCount|comma}} 명</span>
                    </th>
                    <th>
                        <span> {{realBeter|comma}} 명</span>
                    </th>
                    <th>
                        <span>{{accumulateFirstRechargePoint|comma}}</span>
                    </th>
                    <th>
                        <span>{{accumulateDayRechargePoint|comma}}</span>
                    </th>
                    <th>
                        <span> {{rechargeTotal|comma}}</span>
                    </th>
                    <th>
                        <span>{{exchangeTotal|comma}}</span>
                    </th>
                    <th>
                        <span>{{exchangePartnerTotal|comma}}</span>
                    </th>
                    <th>
                        <span :class="{'text-red':(rechargeTotal - exchangeTotal) < 0}">
                            {{(rechargeTotal - exchangeTotal)|comma}}
                        </span>
                    </th>
                    <th>
                         <span :class="{'text-red':(partnerStatisticAmount) < 0}">
                            {{(partnerStatisticAmount)|comma}}
                        </span>
                    </th>
                    <th>
                        {{(sportsBetCashTotal - (sportsBetWinCashTotal+sportsBetCancelCashTotal+sportsBetSpecialCashTotal))|comma}}
                    </th>
                    <th>
                        {{(inplayBetCashTotal - (inplayBetWinCashTotal+inplayBetCancelCashTotal+inplayBetSpecialCashTotal))|comma}}
                    </th>
                    <th>
                        {{(leisureBetCashTotal - (leisureBetWinCashTotal+leisureBetCancelCashTotal+leisureBetSpecialCashTotal))|comma}}
                    </th>
                    <th>
                        {{(honorCasinoBetCashTotal - honorCasinoWinCashTotal)|comma}}
                    </th>
                    <th>
                        {{(honorSlotBetCashTotal - honorSlotWinCashTotal)|comma}}
                    </th>
                </tr>

            </table>
        </div>
    </div>
</template>

<script>

    import {Loading} from 'element-ui';
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import AgentSubTitle from "../../components/agent/AgentSubTitle";
    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import {agentMixin} from "../../common/agent/agentMixin";
    import {agentMainStatistic} from "../../network/agent/commonRequest";
    import agentConst from "../../common/agent/agentConst";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";

    export default {
        name: "AgentMainStatistic",
        components: {
            AgentInfoComp,
            PartnerSubSelectorComp,
            AgentSubTitle,
            DateSelectorComp,
        },
        mixins: [agentMixin],
        data() {
            return {
                agent: {id: null},
                sdata: [],
                months: [
                    {'key': '1월', 'val': '1'}, {'key': '2월', 'val': '2'}, {'key': '3월', 'val': '3'}, {
                        'key': '4월',
                        'val': '4'
                    }, {'key': '5월', 'val': '5'}, {'key': '6월', 'val': '6'},
                    {'key': '7월', 'val': '7'}, {'key': '8월', 'val': '8'}, {'key': '9월', 'val': '9'}, {
                        'key': '10월',
                        'val': '10'
                    }, {'key': '11월', 'val': '11'}, {'key': '12월', 'val': '12'}
                ],
                accumulateBetFailPoint: 0,//낙첨포인트
                accumulateDayRechargePoint: 0,//매충포인트
                accumulateFirstRechargePoint: 0,//첫충포인트
                accumulateManagerAddCash: 0,//수동지급 머니
                accumulateManagerAddPoint: 0,//수동지급 포인트
                exchangePartnerTotal: 0,//총판출금합계
                exchangeTotal: 0,//출금합계
                rechargeTotal: 0,//입금합계
                partnerStatisticAmount: 0,// 정산금액(입금 - 출금  * 프로수)
                siteMemberCount: 0,//신규가입
                siteBlockMemberCount: 0,//신규가입
                realBeter: 0,//실베터


                sportsBetCashTotal : 0,
                sportsBetWinCashTotal : 0,
                sportsBetCancelCashTotal : 0,
                sportsBetSpecialCashTotal : 0,

                inplayBetCashTotal : 0,
                inplayBetWinCashTotal : 0,
                inplayBetCancelCashTotal : 0,
                inplayBetSpecialCashTotal : 0,

                leisureBetCashTotal : 0,
                leisureBetWinCashTotal : 0,
                leisureBetCancelCashTotal : 0,
                leisureBetSpecialCashTotal : 0,


                honorCasinoBetCashTotal: 0,
                honorCasinoWinCashTotal: 0,

                honorSlotBetCashTotal: 0,
                honorSlotWinCashTotal: 0,
            }
        }
        ,
        methods: {
            getMainStatistic() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                agentMainStatistic(this.agent, this.startTime, this.endTime).then(res => {
                    this.accumulateBetFailPoint = 0;
                    this.accumulateDayRechargePoint = 0;
                    this.accumulateFirstRechargePoint = 0;
                    this.accumulateManagerAddCash = 0;
                    this.accumulateManagerAddPoint = 0;
                    this.exchangeTotal = 0;
                    this.rechargeTotal = 0;
                    this.siteMemberCount = 0;
                    this.siteBlockMemberCount = 0;
                    this.realBeter = 0;
                    this.partnerStatisticAmount = 0;
                    this.exchangePartnerTotal = 0;

                    this.sportsBetCashTotal = 0;
                    this.sportsBetWinCashTotal = 0;
                    this.sportsBetCancelCashTotal = 0;
                    this.sportsBetSpecialCashTotal = 0;

                    this.inplayBetCashTotal = 0;
                    this.inplayBetWinCashTotal = 0;
                    this.inplayBetCancelCashTotal = 0;
                    this.inplayBetSpecialCashTotal = 0;

                    this.leisureBetCashTotal = 0;
                    this.leisureBetWinCashTotal = 0;
                    this.leisureBetCancelCashTotal = 0;
                    this.leisureBetSpecialCashTotal = 0;

                    this.honorCasinoBetCashTotal = 0;
                    this.honorCasinoWinCashTotal = 0;

                    this.honorSlotBetCashTotal = 0;
                    this.honorSlotWinCashTotal = 0;


                    this.sdata = res.data.data
                    if (this.sdata) {
                        this.setTotal(this.sdata)
                    }
                    loadingInstance.close();
                })
            },
            search() {
                this.getMainStatistic()
            },
            setPartnerId(partnerId) {
                this.agent.id = partnerId;
                this.getMainStatistic()
            },
            setStartDate(date) {
                this.startTime = date
            },
            setEndDate(date) {
                this.endTime = date
            },
            setTotal(list) {
                list.map(s => {
                    s.normal = agentConst.YES
                    this.accumulateBetFailPoint += s.accumulateBetFailPoint;
                    this.accumulateDayRechargePoint += s.accumulateDayRechargePoint;
                    this.accumulateFirstRechargePoint += s.accumulateFirstRechargePoint;
                    this.accumulateManagerAddCash += s.accumulateManagerAddCash;
                    this.accumulateManagerAddPoint += s.accumulateManagerAddPoint;
                    this.exchangeTotal += s.exchangeTotal;
                    this.exchangePartnerTotal += s.exchangePartnerTotal;
                    this.rechargeTotal += s.rechargeTotal;
                    this.siteMemberCount += s.siteMemberCount;
                    this.siteBlockMemberCount += s.siteBlockMemberCount;
                    this.realBeter += s.realBeter;
                    this.partnerStatisticAmount += s.partnerStatisticAmount;

                    this.sportsBetCashTotal += s.sportsBetCashTotal;
                    this.sportsBetWinCashTotal += s.sportsBetWinCashTotal;
                    this.sportsBetCancelCashTotal  += s.sportsBetCancelCashTotal;
                    this.sportsBetSpecialCashTotal += s.sportsBetSpecialCashTotal;

                    this.inplayBetCashTotal  += s.inplayBetCashTotal;
                    this.inplayBetWinCashTotal  += s.inplayBetWinCashTotal;
                    this.inplayBetCancelCashTotal  += s.inplayBetCancelCashTotal;
                    this.inplayBetSpecialCashTotal  += s.inplayBetSpecialCashTotal;

                    this.leisureBetCashTotal  += s.leisureBetCashTotal;
                    this.leisureBetWinCashTotal  += s.leisureBetWinCashTotal;
                    this.leisureBetCancelCashTotal  += s.leisureBetCancelCashTotal;
                    this.leisureBetSpecialCashTotal  += s.leisureBetSpecialCashTotal;

                    this.honorCasinoBetCashTotal  += s.honorCasinoBetCashTotal;
                    this.honorCasinoWinCashTotal += s.honorCasinoWinCashTotal;

                    this.honorSlotBetCashTotal += s.honorSlotBetCashTotal;
                    this.honorSlotWinCashTotal += s.honorSlotWinCashTotal;

                })


            },
        },
        created() {
            this.getMainStatistic();
        }
    }
</script>

<style scoped>
    .table50 {
        border: 2px solid #0c0e0e;
        margin-top: 5px;
        font-weight: 600;
    }


    .totalText {
        font-size: 12px;
        font-weight: bold;
        font-style: italic;
        color: #3a8ee6;
    }

</style>